import React from 'react';
import { NQuizQuestion, QuizQuestionType, QuizSubmission } from 'redux/schemas/models/progressive-quiz';

// redux
import { useSelector } from 'react-redux';
import { getPointsConfiguration } from 'redux/selectors/points-configurations';
import { useAppDispatch } from 'redux/store';
import { createQuizQuestionSubmission, submitQuizAttempt, unsetQuizQuestionResponse } from 'redux/actions/quizzes';
import { wrapThunkAction } from 'redux/utils';
import { getSkillTagsFromTaggings } from 'redux/selectors/skills-feedback';

import ProgressiveQuizContext, { QuestionContext } from 'quizzes/components/context';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { useTimelineService } from 'timelines/services/react-timeline-service';
import { AngularContext } from 'react-app';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import { config } from '../../../config/pendo.config.json';

type QuizButtonSectionProps = {
  currentQuestion: NQuizQuestion;
  buttonContainerRef: React.MutableRefObject<HTMLDivElement>;
  unsetResponse: Function;
};

const QuizSubmitSection = (props: QuizButtonSectionProps) => {
  const {
    currentQuestion,
    buttonContainerRef,
    unsetResponse,
  } = props;
  const { injectServices } = React.useContext(AngularContext);
  const {
    scrollRef,
    closeModal,
    fetchQuestion,
    progressiveQuiz,
    lectureComponent,
    notMetRequirement,
    currentQuestionIndex,
    currentQuestionResponse,
    isSubmitting,
    setIsSubmitting,
  } = React.useContext(ProgressiveQuizContext);

  const {
    isAnswerMode,
    isMultipleChoiceQuestion,
    isShortAnswerQuestion,
    isNumberAnswerQuestion,
    isStatement,
  } = useQuizModeAndQuestionType();

  const {
    answerState,
  } = React.useContext(QuestionContext);

  const isObject = (variable) => typeof variable === 'object' && variable !== null;
  const hasSelectedAtLeastOneOptions = isObject(answerState) ? Object.values(answerState).some((value) => value) : false;
  const [
    $uibModal,
    TimelinesManager,
  ] = injectServices([
    '$uibModal',
    'TimelinesManager',
  ]);

  const areAttemptsLeft = currentQuestion.completedQuestionAttempts
    < currentQuestion.totalQuestionAttempts;

  const dispatch = useAppDispatch();

  const areQuestionsLeft = (currentQuestionIndex + 1) < progressiveQuiz.totalQuestions;

  const pointsConfiguration = useSelector((state) => (
    getPointsConfiguration(state, progressiveQuiz.pointsConfiguration || 0)
  ));
  const currentLectureId = useSelector(state => state.app.lecturePage?.currentLectureId);
  const skillTags = useSelector(state => getSkillTagsFromTaggings(state, lectureComponent.skillTaggings)) ?? [];
  const timelineService = useTimelineService();

  const renderNextButton = () => (
    <Button
      size='sm'
      onClick={isStatement ? handleNextForStatement : handleNext}
      data-qa={config.pendo.activities.progressiveQuiz.questionModal.next}
      disabled={isSubmitting}
    >
      {t.SHARED.NEXT()}
    </Button>
  );

  const renderNextAction = () => (
    <>
      {(!areQuestionsLeft || (notMetRequirement() && pointsConfiguration?.passingScoreRequired)) ? (
        <Button
          size='sm'
          onClick={handleFinish}
          disabled={isSubmitting}
          data-qa={config.pendo.activities.progressiveQuiz.questionModal.finishQuiz}
        >
          {t.QUIZZES.FINISH_QUIZ()}
        </Button>
      ) : renderNextButton()}
    </>
  );

  const getUserResponse = () => {
    switch (currentQuestion.type) {
      case QuizQuestionType.MULTIPLE_CHOICE:
        return Object.entries(answerState)
          .filter(([key, value]) => value)
          .map(([key]) => Number(key))[0];
      case QuizQuestionType.MULTIPLE_CHOICE_MULTIPLE_ANSWER:
        return Object.entries(answerState)
          .filter(([key, value]) => value)
          .map(([key]) => Number(key));
      case QuizQuestionType.STATEMENT:
        return null;
      case QuizQuestionType.SHORT_TEXT_QUESTION:
        return answerState;
      case QuizQuestionType.NUMBER_QUESTION:
        return answerState;
      default:
        return null;
    }
  };

  const handleSubmitAnswer = async () => {
    setIsSubmitting(true);
    await dispatch(createQuizQuestionSubmission({
      questionId: currentQuestion!.id,
      userResponse: getUserResponse(),
    }));
    setIsSubmitting(false);
  };

  const handleRetryQuestion = () => {
    unsetResponse();
    scrollRef.current.scrollTop = 0;
    dispatch(unsetQuizQuestionResponse(currentQuestion.id));
  };

  const handleNext = () => {
    const nextQuestionIndex = currentQuestionIndex + 1;
    setIsSubmitting(true);
    fetchQuestion({
      page: nextQuestionIndex + 1,
    }).then(() => {
      setIsSubmitting(false);
    });
  };

  const handleNextForStatement = () => {
    const nextQuestionIndex = currentQuestionIndex + 1;
    setIsSubmitting(true);
    if (isAnswerMode && !currentQuestionResponse) {
      wrapThunkAction(dispatch(createQuizQuestionSubmission({
        questionId: currentQuestion!.id,
        userResponse: '',
      }))).then(() => {
        fetchQuestion({
          page: nextQuestionIndex + 1,
        }).then(() => {
          setIsSubmitting(false);
        });
      }).catch(() => {
        setIsSubmitting(false);
      });
      return;
    }
    fetchQuestion({
      page: nextQuestionIndex + 1,
    }).then(() => {
      setIsSubmitting(false);
    });
  };

  const handleSubmitQuizAttempt = () => {
    wrapThunkAction(dispatch(submitQuizAttempt(progressiveQuiz.id))).then(({ payload }) => {
      const {
        pointsReceived,
        leaderboardRank,
        leaderboardPoints,
        priorLeaderboardRank,
        quiz: { progress },
      } = (payload as QuizSubmission);

      closeModal(true);

      timelineService.updateTimeline(currentLectureId);
      if (pointsReceived || skillTags.length > 0) {
        TimelinesManager.updateComponentPointsAndProgress(
          currentLectureId,
          lectureComponent.type,
          lectureComponent.id,
          pointsReceived,
          null,
          progress,
        );

        $uibModal.open({
          templateUrl: 'shared/templates/points-modal.html',
          windowClass: 'points-modal',
          controller: 'PointsModalCtrl as vm',
          resolve: {
            extras: { skillTags },
            pointsReceived,
            leaderboardRank,
            leaderboardPoints,
            priorLeaderboardRank,
          },
        });
      } else {
        TimelinesManager.updateComponentProgress(
          currentLectureId,
          lectureComponent.type,
          lectureComponent.id,
          progress,
        );
      }
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  const handleFinish = () => {
    setIsSubmitting(true);
    if (isStatement) {
      wrapThunkAction(dispatch(createQuizQuestionSubmission({
        questionId: currentQuestion!.id,
        userResponse: '',
      }))).then(() => {
        handleSubmitQuizAttempt();
      });
    } else {
      handleSubmitQuizAttempt();
    }
  };

  const isDisabled = () => {
    if (isShortAnswerQuestion || isNumberAnswerQuestion) {
      return !answerState;
    }
    if (isMultipleChoiceQuestion) {
      return !hasSelectedAtLeastOneOptions;
    }
    return false;
  };
  if (isStatement) {
    return (
      <div ref={buttonContainerRef} className='d-flex justify-content-center button-container'>
        {renderNextAction()}
      </div>
    );
  }
  return (
    <div ref={buttonContainerRef} className='d-flex justify-content-center button-container'>
      {currentQuestionResponse ? (
        <>
          {currentQuestionResponse.isCorrect ? renderNextAction() : (
            <>
              {areAttemptsLeft ? (
                <Button
                  size='sm'
                  onClick={handleRetryQuestion}
                  data-qa={config.pendo.activities.progressiveQuiz.questionModal.retry}
                  data-qa-id={`${config.pendo.activities.progressiveQuiz.questionModal.retry}_${currentQuestion.id}`}
                >
                  {t.QUIZZES.RETRY_QUESTION()}
                </Button>
              ) : renderNextAction()}
            </>
          )}
        </>
      ) : (
        <Button
          size='sm'
          onClick={handleSubmitAnswer}
          disabled={isDisabled() || isSubmitting}
          data-qa={config.pendo.activities.progressiveQuiz.questionModal.submitAnswer}
          data-qa-id={`${config.pendo.activities.progressiveQuiz.questionModal.submitAnswer}_${currentQuestion.id}`}
        >
          {t.QUIZZES.SUBMIT_ANSWER()}
        </Button>
      )}
    </div>
  );
};

export default QuizSubmitSection;
